import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/get-started"
import AboutUs from "../components/about-us"
import FullWidthImage from "../components/full-width-image"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <GetStarted />
    <AboutUs />
    <FullWidthImage src="img/cooking.jpg" imgStyle={{"object-position": "top"}} style={{"height": "700px"}}/>
  </Layout>
)

export default IndexPage
