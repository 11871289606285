import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const FullWidthImage = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
  query {
    allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`)

const match = useMemo(() => (
  data.allFile.edges.find(({ node }) => src === node.relativePath)
), [ data, src ])

return (
  <Img
    fluid={match.node.childImageSharp.fluid}
    {...props}
  />
)}

FullWidthImage.propTypes = {
  src: PropTypes.node.isRequired,
}

export default FullWidthImage
