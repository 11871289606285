import React from 'react'

import './get-started.css'
import '../assets/common/button.css'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const GetStarted = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "img/get-started-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="get-started-hero">
      <BackgroundImage
        className="get-started-hero__image"
        fluid={data.backgroundImage.childImageSharp.fluid}
        style={{
          backgroundAttachment: 'fixed',
        }}
      ></BackgroundImage>
      <div className="get-started-hero__overlay"></div>
      <div className="get-started-hero__content">
        <h2 className="text-6xl font-semibold">Simmr</h2>
        <h3 className="text-2xl font-semibold">Community Cookbooks</h3>
        <a className="button button--primary" href="https://app.simmr.us">
          Get Started
        </a>
      </div>
    </div>
  )
}

export default GetStarted
