import React from 'react'

import './about-us.css'

const AboutUs = () => (
  <div className="about-us">
    <div className="about-us__content">
      <h2 className="text-4xl font-semibold mb-10">About Us</h2>
      <h3 className="text-xl font-semibold text-green-500 text-center mb-8">
        Cookbooks Simplified, in the Cloud
      </h3>
      <p className="font-normal font-semibold leading-loose text-center max-w-xl">
        We realize that good food is a binding force for any community. You want
        to share you delicious creations with loved ones. You may find recipes
        in many places on the internet, in your printed cookbooks or in your
        grandmother's memory. With Simmr, you can create personalized cookbooks,
        compile all your favorite recipes and share them with the people you
        love.
      </p>
    </div>
  </div>
)

export default AboutUs
